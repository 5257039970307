<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-list-item>
        <v-row align="center" class="text-start" justify="center" no-gutters>
          <v-list-item-avatar class="my-auto">
            <v-icon x-large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-html="`<b>${member.first_name} ${member.last_name}</b>`"/>
            <v-list-item-subtitle v-text="member.roles_names"/>
          </v-list-item-content>

          <v-list-item-content v-if="groupId" class="text-end">
            <v-list-item-subtitle v-html="`${member.isAdminInGroup(groupId) ? 'Admin' : 'Member'}`"/>
          </v-list-item-content>
          <v-col v-if="multiSelect && showSelectButton" class="mx-2" cols="auto">
            <v-checkbox @change="$emit('check-changed', !!$event)"/>
          </v-col>
          <v-col v-if="!multiSelect && showSelectButton" class="mx-2" cols="auto">
            <v-radio :value="member" @change="$emit('check-changed', member)"/>
          </v-col>
        </v-row>
      </v-list-item>
      <slot/>
    </v-col>
    <v-col cols="12">
      <slot name="divider"/>
    </v-col>
  </v-row>
</template>

<script>
import Staff from '@/modules/app/models/staff'

export default {
  name: 'KurccStaffListItem',
  props: {
    member: {
      type: Staff,
      required: true
    },
    groupId: {
      type: String,
      required: false
    },
    multiSelect: {
      type: Boolean,
      required: false
    },
    showSelectButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  }
}
</script>
